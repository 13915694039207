.VendorItem {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
}

.VendorItem-content {
    padding: 10px;
}

.VendorItem-name {
    font-size: 1rem;  
    font-weight: 500;
}

.VendorItem-location {
    background-color: #51B16B;
    color: #ffffff;
    font-size: 0.8rem;
    padding: 2px 3px 2px 3px;
    border-radius: 3px;
    margin-top: 10px;
}

.VendorItem-delivery {
    padding-right: 10px;
    font-size: 0.8rem;
    font-weight: 500;
}

.VendorItem-cover > img{
    border-radius: 8px;
}