.Login {
   padding-right: 16px;
   padding-left: 16px;
   margin-top: 100px;
}

.Login-content {
   
}

.Login-description {
   margin-top: 10px;
   margin-bottom: 10px;
   color: #777777;
   line-height: 1.3;
   font-size: 13px;
}

.Login-pin {
   width: 100%;
   height: 50px;
   font-size: 16px;
   border: solid 1px #a8a8a8;
   border-radius: 8px;
   background-color: #f5f5f5;
   color: #000000;
   padding-top: 14px;
   padding-bottom: 14px;
   text-align: center;
   margin-top: 10px;
}

.Login-pin::placeholder {
   color: #a8a8a8;
}